import React, { useState } from "react";
import DownBlackArrow from "../assets/images/down_black_arrow.svg";
import UpWhiteArrow from "../assets/images/up_white_arrow.svg";

const accordionData = [
  {
    title:
      "What is Mixxer and how can it enhance my social experience by helping me discover and join Mixxers?",
    content:
      "Mixxer simplifies meeting friends or making new connections by streamlining the planning process. There is no need to swipe for connections or navigate group chats. Simply create a Mixxer with all the outing's details for others to see, and wait for them to join the fun!",
  },
  {
    title: "How do I signup for Mixxer?",
    content:
      "You can sign up for Mixxer by downloading the app and following the prompts to create a profile.",
  },
  {
    title: "How do I find Mixxers near me using the app?",
    content:
      "You can find Mixxers near you on the Home Page feed under “Recommendations”.",
  },
  {
    title: "What should I do if I encounter inappropriate content?",
    content:
      "Use the “Report” button to notify us of any inappropriate content, and we will review the profile. Any person displaying inappropriate content will be removed from the app.",
  },
  {
    title: "Is it possible to create my own Mixxers?",
    content:
      "Yes, anyone can create their own Mixxers! Click the “+” button in the center of the bottom menu to get started.",
  },
  {
    title: "Are there any costs associated with using Mixxer?",
    content:
      "Mixxer is completely free to use and offers all features! Users have the option to upgrade to a Standard Plan ($4.99) or a Premium Plan ($9.99), depending on their preferred level of activity.",
  },
  {
    title: "How can I invite friends to a Mixxer?",
    content:
      "When creating a Mixxer, select the “Invite” button to invite your friends on the app. Ensure your friends have a Mixxer profile so they can see the details!",
  },
  {
    title: "Can I see who is attending a Mixxer I'm interested in?",
    content:
      "Yes, you can see the profile photo of any Mixxer attendee. You can then click on their profile photo for more information.",
  },
  {
    title: "How do I update my profile information?",
    content:
      "To update your profile information, use the menu button in the top right corner of your profile.",
  },
];

const AccordionItem = ({ title, content, isOpen, onClick }) => (
  <div
    className={`mb-4 ${
      isOpen ? "text-white rounded-xl bg-primary" : "text-dark"
    }`}
  >
    <div
      className={`w-full text-left focus:outline-none rounded-xl flex justify-between ${
        isOpen ? "px-6 pt-6 max-sm:pt-3 pb-2" : "p-4 border border-lighter"
      }`}
      onClick={onClick}
    >
      <div className="w-[90%]">
        <h4 className="text-xl max-sm:text-md font-medium flex justify-between items-center">
          {title}
        </h4>
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <span
          className={`transform transition-transform duration-300`}
        >
          <img
            src={isOpen ? UpWhiteArrow : DownBlackArrow}
            className="min-w-[9.67px] min-h-[15.9px]"
            alt="arrow"
            width={"9.67px"}
            height={"15.9px"}
          />
        </span>
      </div>
    </div>
    {isOpen && (
      <div className="px-5 pb-4 bg-primary rounded-xl">
        <div className="flex">
          <div className="border rounded-xl border-white" />
          <p className="pl-3 w-[73%] max-sm:w-[100%] text-md max-sm:text-base font-normal leading-relaxed ">
            {content}
          </p>
        </div>
      </div>
    )}
  </div>
);

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="rounded-md">
      {accordionData.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onClick={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default FAQSection;
