import { theme as tailwindTheme } from 'tailwindcss/defaultTheme';

export const lightTheme = {
    ...tailwindTheme,
    colors: {
        primary: '#7E6C54',
        secondary: '#E3E0DD',
        brown1: "#CBC4BB"
    },
};

export const darkTheme = {
    ...tailwindTheme,
    colors: {
        primary: '#1A202C',
        secondary: '#2D3748',
    },
};
