import React from "react";
import appStore from "../assets/images/appstore.png";
import playStore from "../assets/images/playstore.png";

const GetStartedSection = () => {
  return (
    <>
      <div className="bg_join_mixxer lg:p-24 md:p-14 max-sm:p-4 rounded-xl">
        <div className=" flex flex-col text-center justify-center text-white">
          <h3 className="text-12xl max-sm:text-5xl text-white font-lightBold mb-4 max-sm:mb-1">
            Get Started with Mixxer
          </h3>
          <p className="text-xl max-sm:text-md max-sm:w-[80%] max-sm:mx-auto text-white mb-6 max-sm:mb-1">
            Create, discover, and join Mixxer. Download Mixxer now to get
            started!
          </p>
          <div className=" w-[280px] flex justify-center mx-auto space-x-4">
            <img src={playStore} className="w-[134px] h-[45px]" alt="social" />
            <img src={appStore} className="w-[134px] h-[45px]" alt="social" />
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStartedSection;
