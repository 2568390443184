import React, { useState } from "react";
import ts1 from "../../assets/images/001-private-messaging.svg";
import ts2 from "../../assets/images/002-group-chats.svg";
import ts3 from "../../assets/images/003-chat-history.svg";
import ts4 from "../../assets/images/004-media-sharing.svg";

import ms1 from "../../assets/images/mobile_social1.png";
import ms2 from "../../assets/images/mobile_social2.png";
import ms3 from "../../assets/images/mobile_social3.png";
import ms4 from "../../assets/images/mobile_social4.png";

import ds1 from "../../assets/images/001-search.svg";
import ds2 from "../../assets/images/002-equalizer.svg";
import ds3 from "../../assets/images/003-security.svg";
import ds4 from "../../assets/images/004-arrow.svg";

import ds5 from "../../assets/images/005-podcast.svg";
import ds6 from "../../assets/images/006-list.svg";
import ds7 from "../../assets/images/007-friend-request.svg";
import ds8 from "../../assets/images/008-update.svg";

import ds9 from "../../assets/images/009-self-confident.svg";
import ds10 from "../../assets/images/010-connection.svg";
import ds11 from "../../assets/images/011-tasks.svg";
import ds12 from "../../assets/images/012-bookmark.svg";

import mobileView from "../../assets/images/explore-phone_mobile.svg";

const socialSections = [
  "Stay Connected with Ease",
  "Plan Your Perfect Mixxer",
  "Always in Loop",
  "Your Social Hub",
];

const socialSectionWithTabs = [
  {
    title: "Seamless Socializing with Mixxer",
    desc: "Discover the ultimate platform for planning and enjoying small group outings with ease. Mixxer offers a suite of features designed to enhance your social experiences and keep you connected with friends and new acquaintances.",
    subTitle: "Stay Connected with Ease",
    icon: ms1,
    subDesc:
      "Enhance your Mixxer experience with our intuitive chat feature designed to keep you connected with your social circles seamlessly. Whether you're planning your next outing or sharing memorable moments, our chat functionality offers everything you need.",
    details: [
      {
        title: "Private Messaging",
        icon: ts1,
        description:
          "Keep your connections alive by messaging users directly. This feature ensure your friendships remain strong, allowing you to share updates, plan Mixxers, and maintain your bond with friends.",
        // "Send messages directly to users you've connected with, ensuring you stay in touch with friends old and new.",
      },
      {
        title: "Group Chat",
        icon: ts2,
        description:
          "Engage with attendees of Mixxers you're part of or hosting. Your group chat is exclusively for those accepted to a specific Mixxer, ensuring a focused and relevant conversation.",
      },
      {
        title: "Comprehensive Chat History",
        icon: ts3,
        description:
          "Access the chat history of every Mixxer you've been part of, along with all your private messaging threads with friends, so you can easily revisit essential talks, catch up on important discussions, and keep the conversation going.",
        // "Access the chat history of all past and current Mixxers you've attended, as well as private messaging history with your friends. This makes it easy to revisit important discussions and moments.",
      },
      {
        title: "Media Sharing",
        icon: ts4,
        description:
          "Elevate your conversations by uploading and sharing pictures within chats. Share photos from your Mixxers to highlight the fun moments and keep the excitement alive.",
      },
    ],
  },
  {
    subTitle: "Plan Your Perfect Mixxer",
    icon: ms1,
    subDesc:
      "Unleash your creativity and plan the perfect Mixxer with our comprehensive creation feature. Whether you're organizing a casual get-together or a themed gathering, Mixxer offers all the tools you need to make it happen.",
    details: [
      {
        title: "Add Detailed Information",
        icon: ds1,
        description:
          "Provide all the essential details for your Mixxer, including a captivating description, schedule, location, and photos to set the right tone.",
      },
      {
        title: "Customization Options",
        icon: ds2,
        description:
          "Tailor your Mixxer to fit your desired audience perfectly. Set attendee limits, specify gender preferences, and apply age restrictions to ensure a curated experience.",
      },
      {
        title: "Privacy Settings",
        icon: ds3,
        description:
          "Decide how you want to share your Mixxer. Choose between public visibility, private (invite-only), or sharing exclusively with friends.",
      },
      {
        title: "Flexible Formats",
        icon: ds4,
        description:
          "Select whether your Mixxer will be in-person, virtual, or a hybrid of both. For virtual Mixxers, take advantage of integrated video chatting, allowing attendees to join via a link and participate seamlessly.",
      },
    ],
  },
  {
    subTitle: "Always in Loop",
    icon: ms1,
    subDesc:
      "Stay in the loop with Mixxer’s tailored notification feature, designed to keep you informed and engaged with all your Mixxer activities. Whether you're a host or an attendee, our notifications ensure you never miss a beat.",
    details: [
      {
        title: "Host Notifications",
        icon: ds5,
        description:
          "Receive instant updates about who requests to join your Mixxers, helping you manage your gatherings effortlessly.",
      },
      {
        title: "Attendee Notifications",
        icon: ds6,
        description:
          "Get timely alerts when you're accepted to a Mixxer, so you can plan and prepare for your upcoming social activities.",
      },
      {
        title: "Friend Requests",
        icon: ds7,
        description:
          "Stay connected by seeing and responding to friend requests directly from your notifications tab.",
      },
      {
        title: "Mixer Updates",
        icon: ds8,
        description:
          "Keep track of all your Mixxer-related activities with reminders for when a Mixxer is about to start, friendly check-ins during a Mixxer, feedback requests about your Mixxer experience, and reminders to upload photos of your Mixxer with your new friends.",
      },
    ],
  },
  {
    subTitle: "Your Social Hub",
    icon: ms1,
    subDesc:
      "Your Mixxer profile is your personal space to showcase who you are and what you love. Connect with like-minded individuals, share your passions, and keep track of your social activities with these features designed to make every Mixxer a memorable experience.",
    details: [
      {
        title: "Express Yourself",
        icon: ds9,
        description:
          "Share your name, age, bio, and interests to let others know more about you and what you're passionate about.",
      },
      {
        title: "Friend Connections",
        icon: ds10,
        description:
          "View and manage your list of friends, making it easy to stay connected with those you care about.",
      },
      {
        title: "Mixxer Participation",
        icon: ds11,
        description:
          "See the Mixxers you're currently hosting and attending. Track the number of Mixxers you've hosted or participated in, highlighting your social engagement.",
      },
      {
        title: "Bookmarks",
        icon: ds12,
        description:
          "Keep track of your favorite Mixxers by bookmarking them for easy access and future reference.",
      },
    ],
  },
];

const SocializingSection = () => {
  const [active, setActive] = useState(0);
  const activeTabDetail = socialSectionWithTabs[active];
  return (
    <>
      <div className="bg-brown1 ">
        <div className="bg_social_web_screen mx-auto max-w-[1420px]">
          <div className="py-[80px] px-[150px] md:px-[10px] flex flex-col max-sm:hidden sm:hidden md:hidden lg:flex xl:flex  max-w-[1180px] mx-auto">
            <div className="">
              <div className="text-center mb-6">
                <h3 className="text-12xl font-lightBold text-dark">
                  Seamless Socializing with Mixxer
                </h3>
              </div>
              <p className="text-lightbrown text-xl font-normal leading-relaxed w-[73%] text-center mx-auto">
                Discover the ultimate platform for planning and enjoying small
                group outings with ease. Mixxer offers a suite of features
                designed to enhance your social experiences and keep you
                connected with friends and new acquaintances.
              </p>
            </div>
            <div className="mt-3 ">
              <div
                className="text-2xl font-medium text-center text-lightbrown border-b border-lightbrown dark:text-lightbrown dark:border-lightbrown"
                style={{ borderColor: "rgba(126, 108, 84, 0.4)" }}
              >
                <ul className="flex flex-wrap justify-between -mb-px">
                  {socialSections?.map((tab, index) => (
                    <li
                      key={index}
                      onClick={() => setActive(index)}
                      className="relative py-4 text-lightBrown cursor-pointer rounded-t-lg hover:text-primary dark:hover:text-primary"
                    >
                      <span>{tab}</span>
                      <span
                        className={`absolute bottom-0 left-0 w-full h-[2px] transition-colors duration-300 ${
                          active === index ? "bg-primary" : "bg-transparent"
                        }`}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex mt-10 items-center min-h-[700px]">
                <div className="w-[40%]">
                  <img
                    src={activeTabDetail?.icon}
                    className=" mx-auto w-[406.66px] h-[571.78px] object-contain"
                    alt="mixxer"
                  />
                </div>
                <div className="w-[50%] lg:w-[60%]">
                  <h1 className="text-dark text-7xl font-lightBold">
                    {activeTabDetail?.subTitle}
                  </h1>
                  <p className="font-normal text-2xl text-lightbrown mt-5">
                    {activeTabDetail?.subDesc}
                  </p>

                  <div className="flex flex-wrap gap-6 mt-10">
                    {activeTabDetail?.details?.map((content) => {
                      return (
                        <div className=" w-[48%]">
                          <img
                            src={content?.icon}
                            className=" mb-3 w-[58px] h-[58px]"
                          />
                          <h1 className="text-2xl text-dark font-lightBold">
                            {content?.title}
                          </h1>
                          <p className="font-normal text-md text-lightbrown">
                            {content?.description}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className=""> */}
      <div className=" flex flex-col max-sm:flex sm:flex md:flex lg:hidden xl:hidden gap-[40px]">
        {socialSectionWithTabs?.map((feature, index) => {
          return (
            <div
              className={` ${
                index == 1 || index == 3 ? "bg-light" : "bg-brown1"
              } py-5 px-5 text-center`}
            >
              {feature?.title && (
                <h1 className="text-5xl text-dark font-lightBold mt-3">
                  {feature?.title}
                </h1>
              )}
              {feature?.desc && (
                <p className="text-md font-normal text-lightbrown leading-relaxed mb-5">
                  {feature?.desc}
                </p>
              )}

              <h4 className="text-2xl font-lightBold text-dark mb-2">
                {feature?.subTitle}
              </h4>
              <div className="bg_xx_image_mobile mt-5 h-[352.24px] ">
                <img
                  src={feature?.icon}
                  className="mx-auto w-[331px] h-[352.24px] object-contain"
                  width={"331px"}
                  height={"352px"}
                />
              </div>
              <p className="text-md font-normal text-lightbrown leading-relaxed">
                {feature?.subDesc}
              </p>
            </div>
          );
        })}
      </div>
      {/* </div> */}
    </>
  );
};

export default SocializingSection;
