import React, { useEffect, useRef } from "react";
import logo from "../assets/images/white_logo.svg";

const Drawer = ({ isOpen, handleClose, activeTab, tabs }) => {
  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleClose(null, "outsideClick");
      }
    };

    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.style.overflow = "";
    }

    // Clean up on unmount
    return () => {
      document.body.style.overflow = "";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div
        ref={drawerRef}
        className={`drawer fixed top-0 left-0 py-10 h-full w-[75%] bg-dark text-white transform transition-transform duration-500 ease-out z-50 ${
          isOpen ? "animate-slideIn" : "-translate-x-[50%]"
        }`}
      >
        <div className="px-5">
          <img src={logo} alt="Logo" width={158} height={30} className="" />
        </div>
        <div className="px-5 mt-14">
          {tabs?.map(({ label, id }) => (
            <div key={id} className="my-[10px]">
              <button
                onClick={() => handleClose(id, "icon")}
                className={`flex flex-row items-center py-2 h-[38px] text-white w-full ${
                  id === activeTab && window?.location?.pathname === "/"
                    ? "bg-white pl-1"
                    : ""
                }`}
              >
                <span
                  className={`text-medium text-3xxl ${
                    id === activeTab && window?.location?.pathname === "/"
                      ? "text-dark"
                      : ""
                  }`}
                >
                  {label}
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Drawer;
