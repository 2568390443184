import React, { useState } from "react";

import experiences1_brown from "../../assets/images/experiences1_brown.svg";
import experiences2_brown from "../../assets/images/experiences2_brown.svg";
import experiences3_brown from "../../assets/images/experiences3_brown.svg";
import experiences4_brown from "../../assets/images/experiences4_brown.svg";
import experiences5_brown from "../../assets/images/experiences5_brown.svg";
import experiences6_brown from "../../assets/images/experiences6_brown.svg";

import experiences1_white from "../../assets/images/experiences1_white.svg";
import experiences2_white from "../../assets/images/experiences2_white.svg";
import experiences3_white from "../../assets/images/experiences3_white.svg";
import experiences4_white from "../../assets/images/experiences4_white.svg";
import experiences5_white from "../../assets/images/experiences5_white.svg";
import experiences6_white from "../../assets/images/experiences6_white.svg";

const features = [
  {
    title: "Personalized Outings",
    description:
      "Tailor one-on-one or small group outings to your unique interests and schedule. From spontaneous coffee dates to planned weekend hikes, Mixxer ensures every outing is meaningful and enjoyable.",
    brownIcon: experiences1_brown,
    whiteIcon: experiences1_white,
  },
  {
    title: "Interest-Based Connections",
    description:
      "Facilitate platonic relationships across genders by connecting with individuals who share your passions. Mixxer's advanced matching algorithm helps you find like-minded people, making it easier to form deep, meaningful friendships.",
    brownIcon: experiences2_brown,
    whiteIcon: experiences2_white,
  },
  {
    title: "Effortless Coordination",
    description:
      "Eliminate group chat confusion. Mixxer simplifies organizing get-togethers, ensuring everyone is on the same page. Focus on having fun while we handle the logistics.",
    brownIcon: experiences3_brown,
    whiteIcon: experiences3_white,
  },
  {
    title: "Cost-Effective Experience",
    description:
      "Enjoy Mixxer without hidden fees or high subscription costs. Our affordable solution makes it easy to maintain an active social life without financial strain.",
    brownIcon: experiences4_brown,
    whiteIcon: experiences4_white,
  },
  {
    title: "Community-driven Features",
    description:
      "Benefit from features developed based on user feedback. Mixxer listens to its community, ensuring a continually improving experience tailored to your socializing needs.",
    brownIcon: experiences5_brown,
    whiteIcon: experiences5_white,
  },
  {
    title: "On-Demand Socializing",
    description:
      "Experience the freedom of instant socializing with Mixxer. Whether it’s an unexpected free afternoon or a sudden urge to socialize, our platform connects you with like-minded individuals for immediate and enjoyable Mixxers.",
    brownIcon: experiences6_brown,
    whiteIcon: experiences6_white,
  },
];

const ExperienceSection = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  return (
    <>
      <div className="text-center mb-8">
        <h3 className="text-12xl max-sm:text-5xl text-dark font-lightBold">
          Seamless Socializing, Unforgettable Experiences
        </h3>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 max-sm:gap-y-3 gap-5 ">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`rounded-xl shadow p-6 max-sm:p-3 transition-colors duration-300 ${
              hoveredIndex === index
                ? "bg-primary text-white"
                : "bg-secondary text-dark"
            }`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img
              src={
                hoveredIndex === index ? feature.whiteIcon : feature.brownIcon
              }
              className="w-[88px] h-[50.1px] mb-[22.9px]"
              width={88}
              alt="feature"
              height={50.1}
            />
            <h4 className="text-2xl max-sm:text-lg font-lightBold mb-2">
              {feature.title}
            </h4>
            <p className="text-md max-sm:leading-[20px] leading-relaxed">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default ExperienceSection;
