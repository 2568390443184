import ThemeProvider from '../src/utils/themeProvider';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css'
import Disclaimer from './pages/Disclaimer';
import TermsPolicies from './pages/Terms&Policies';
import EULASection from './pages/EULA';
import PrivacyPolicies from './pages/PrivacyPolicies';
import HomePage from './pages/HomePage';

function MyApp() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/terms" element={<TermsPolicies />} />
          <Route path="/eula" element={<EULASection />} />
          <Route path="/privacy" element={<PrivacyPolicies />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default MyApp;
