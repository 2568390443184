import React from "react";
import axios from "axios";

const ContactSection = () => {
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false);
  const [messageType, setMessageType] = React.useState(''); // 'success' or 'error'
  const [alertMessage, setAlertMessage] = React.useState(''); // 'success' or 'error'
  const showSuccessMessage = () => {
    setMessageType('success');
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000); // Show message for 2 seconds
  };

  const showErrorMessage = () => {
    setMessageType('error');
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000); // Show message for 2 seconds
  };
  const hanldeSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !message) {
      setMessageType('error');
      setShowMessage(true);
      setAlertMessage('Please check and add data in the above fields!')
      setTimeout(() => {
        setShowMessage(false);
      }, 2000); // Show message for 2 seconds
      return;
    }
    axios.post('https://api.mixxerco.com/api/app/contact',
      {
        name: name,
        email: email,
        message: message,
      }
    )
      .then((res) => {
        if (res.data.status) {
          console.log(res.data)
          setMessageType('success');
          setShowMessage(true);
          setAlertMessage('Your query has been sent successfully')
          setEmail('')
          setName('')
          setMessage('')
          setTimeout(() => {
            setShowMessage(false);
          }, 2000); // Show message for 2 seconds
        }
      })
      .catch((error) => {
        console.error(error);
        setMessageType('error');
        setShowMessage(true);
        setAlertMessage('Error try again later!')
        setTimeout(() => {
          setShowMessage(false);
        }, 2000); // Show message for 2 seconds
      });
  }
  return (
    <>
      <div className="bg-brown1 rounded-md p-6 ">
        <h3 className="text-12xl max-sm:text-5xl text-dark font-lightBold mb-4 text-center">
          Have a question or need any assistance?
        </h3>
        <div className="lg:w-[66.1%] mx-auto text-center">
          <p className="text-2xl max-sm:text-md text-lightbrown font-normal mb-6">
            We're here to help! Please fill out the form below, and we'll get
            back to you as soon as possible.
          </p>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Full Name"
              className="w-full bg-brown2 px-4 py-3 rounded-md border-brown2 focus:outline-none placeholder:text-dark focus:border-dark transition duration-300 ease-in-out"
              onChange={(e) => { setName(e.target.value) }}
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              placeholder="Email Address"
              className="w-full bg-brown2 px-4 py-3 rounded-md border-brown2 focus:outline-none placeholder:text-dark focus:border-dark transition duration-300 ease-in-out"
              onChange={(e) => { setEmail(e.target.value) }}
            />
          </div>
          <div className="mb-4 text-center">
            <textarea
              placeholder="Please provide details of your issue or feedback"
              className="w-full bg-brown2 px-4 py-3 rounded-md border-brown2 focus:outline-none placeholder:text-dark focus:border-dark transition duration-300 ease-in-out resize-none"
              onChange={(e) => { setMessage(e.target.value) }}
            />
            {showMessage && (
              <div
                className={`fixed top-4 bg-opacity-90 text-white px-4 py-2 rounded-md shadow-md transition-opacity duration-1000 ${messageType === 'success' ? 'bg-green-500' : 'bg-red-500'
                  } ${showMessage ? 'opacity-100' : 'opacity-0'}`}
              >
                {alertMessage}
              </div>
            )}
            <button className="bg-primary text-white px-8 py-3 mt-2 rounded-md hover:bg-primary transition duration-300 ease-in-out" onClick={hanldeSubmit}>
              Contact us
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
