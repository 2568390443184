import React from "react";

import large from "../assets/images/large_splash.jpg";
import small from "../assets/images/small_splash.jpg";

const AboutSection = () => {
  return (
    <>
      <div className="relative max-sm:w-[336px] md:w-[596px] lg:w-[596px] xl:w-[596px] max-sm:hidden ">
        <div
          className="relative max-sm:w-[302px] md:w-[539px] lg:w-[500px] xl:w-[539px] 
             max-sm:h-[216px] md:h-[386px] lg:h-[386px] xl:h-[386px] "
        >
          <img
            src={large}
            alt="Large party"
            className="rounded-lg max-sm:w-[302px] md:w-[539px] lg:w-[500px] xl:w-[539px] 
                max-sm:h-[216px] md:h-[386px] lg:h-[386px] xl:h-[386px] object-cover"
          />
        </div>

        <div className="absolute bottom-[-4px] right-[0%]">
          <img
            src={small}
            alt="Small party"
            className="rounded-lg max-sm:w-[125px] md:w-[227px] lg:w-[227px] xl:w-[227px] 
            max-sm:h-[92px] md:h-[162.43px] lg:h-[162.43px] xl:h-[162.43px] object-cover border-4 border-light"
          />
        </div>
      </div>
      <div className="flex flex-col justify-center lg:p-4 lg:w-[45%] md:w-full max-sm:w-full sm:w-full max-sm:mt-4 ">
        <h3 className="text-12xl text-primary font-lightBold max-sm:text-center">
          About Mixxer
        </h3>
        <p className="text-lg max-sm:text-md max-md:text-md text-lightbrown font-normal lg:leading-[30px] sm:leading-[20px] leading-relaxed mt-2 max-sm:text-center ">
          Mixxer is a vibrant social app designed to enhance your social life by
          making it easy to plan and organize one-on-one and small group outings
          with friends or new acquaintances. Founded by the dynamic husband and
          wife duo, Dallas and Charity Locke, Mixxer is based in the heart of
          Houston, Texas. Dallas brings his expertise in automation engineering,
          while Charity's background in Marketing Analytics adds a strategic
          edge to the company.
        </p>
      </div>
    </>
  );
};

export default AboutSection;
