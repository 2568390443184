import React from "react";
import Layout from "../layout";

const PrivacyPolicy = () => {
  return (
    <div className="bg-light w-[80%] mx-auto py-20">
      <h1 className="text-12xl max-sm:text-5xl font-lightBold text-dark mb-4">
        Privacy Policy
      </h1>

      <p className=" text-2xl font-lightBold text-dark mb-4">
        Effective Date: May 8, 2024
      </p>
      <p className="mb-4 text-lg text-lightbrown font-normal">
        Thank you for using Mixxer! This Privacy Policy explains how we collect,
        use, disclose, and protect your personal information when you use our
        mobile application (the "App") and related services.
      </p>

      <h2 className="text-2xl font-medium">Information We Collect</h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">Personal Information:</span> When you
          create an account, we collect your name, email address, profile
          picture, and other optional information you choose to provide.
        </li>
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">User Content:</span> We may collect
          content that you create or share on Mixxer, including photos, videos,
          posts, and comments.
        </li>
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">Usage Information:</span> We
          automatically collect certain information about your device and how
          you interact with the App, such as your IP address, device type,
          browser type, and usage patterns.
        </li>
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">Location Information:</span> With your
          consent, we may collect and process your precise location data to
          provide location-based services.
        </li>
      </ol>

      <h2 className="text-2xl font-medium">
        How We Use Your Information
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">To Provide and Improve the App:</span>{" "}
          We use your information to operate, maintain, and enhance Mixxer,
          including developing new features and optimizing user experience.
        </li>
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">To Communicate with You:</span> We may
          send you service-related announcements, updates, and promotional
          messages.
        </li>
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">To Protect Our Users:</span> We use
          your information to detect and prevent fraud, abuse, or security
          incidents.
        </li>
      </ol>

      <h2 className="text-2xl font-medium">Sharing Your Information</h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">With Third Parties:</span> We may
          share your information with third-party service providers that assist
          us in providing and improving our services.
        </li>
        <li className="mb-5 text-lg text-lightbrown font-normal">
          <span className="text-dark">Legal Compliance:</span> We may
          disclose your information if required by law or in response to valid
          legal requests.
        </li>
      </ol>

      <h2 className="text-2xl font-medium">Data Security</h2>
      <p className="mb-4 text-lg text-lightbrown font-normal">
        We implement industry-standard security measures to protect your
        information from unauthorized access and use.
      </p>

      <h2 className="text-2xl font-medium">Your Choices</h2>
      <p className="mb-4 text-lg text-lightbrown font-normal">
        You can manage your account settings and preferences within the App,
        including opting out of promotional communications.
      </p>

      <h2 className="text-2xl font-medium">Children's Privacy</h2>
      <p className="mb-4 text-lg text-lightbrown font-normal">
        Mixxer is not intended for users under the age of 18. We do not
        knowingly collect personal information from children.
      </p>

      <h2 className="text-2xl font-medium">Updates to the Policy</h2>
      <p className="mb-4 text-lg text-lightbrown font-normal">
        We may update this Privacy Policy from time to time. We will notify you
        of any material changes by posting the updated policy on our website or
        within the App.
      </p>

      <h2 className="text-2xl font-medium">Contact Us</h2>
      <p  className="mb-4 text-lg text-lightbrown font-normal">
        If you have any questions or concerns about our Privacy Policy or
        practices, please contact us at admin@mixxerco.com.</p>
    </div>
  );
};

export default Layout(PrivacyPolicy);
