import React from "react";
import Layout from "../layout";

const TermsPolicies = () => {
  return (
    <div>
      <div className="bg-light w-[80%] mx-auto py-20">
        <h1 className="text-12xl max-sm:text-5xl font-lightBold text-dark mb-4">
          Terms OF SERVICES
        </h1>
        <h6 className="text-2xl font-lightBold text-dark mb-4">
          Effective Daye: May 8, 2024
        </h6>
        <p className="text-lg text-lightbrown text-lg mb-4">
          These Terms of Service ("Terms") govern your access to and use of the
          Mixxer mobile application (the "App") and related services provided by
          Mixxer, Inc. ("Mixxer," "we," "us," or "our"). By accessing or using
          the App, you agree to be bound by these Terms. If you do not agree to
          these Terms, please do not use the App.
        </p>
        <h2 className="text-2xl font-medium text-dark mb-4">
          1. Use Of the App
        </h2>

        <div className="mb-5">
          <span className="text-lg font-medium text-dark">
            1.1 Eligibility :&nbsp;
          </span>
          <span className="mb-4 text-lightbrown text-lg">
            You must be at least 18 years old to use the App. If you are under
            18 years old, you must have permission from a parent or guardian.
          </span>
        </div>

        <div className=" mb-5">
          <span className="text-lg font-medium text-dark">
            1.2 Account Registration :&nbsp;
          </span>
          <span className="mb-4 text-lightbrown text-lg">
            You agree to provide accurate and complete information when creating
            your account. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account.
          </span>
        </div>

        <div className=" mt-3">
          <span className="text-lg font-medium text-dark">
            1.3 Prohibited Conduct :&nbsp;
          </span>
          <span className=" text-lightbrown text-lg">
            You agree not to engage in any of the following prohibited
            activities:
          </span>
        </div>
        <ul className="list-disc list-inside mb-4">
          <li className="text-lightbrown text-lg">
            Violating any applicable laws or regulations.
          </li>
          <li className="text-lightbrown text-lg">
            Impersonating another person or entity.
          </li>
          <li className="text-lightbrown text-lg">
            Posting harmful, defamatory, or inappropriate content.
          </li>
          <li className="text-lightbrown text-lg">
            Attempting to gain unauthorized access to the App or its related
            systems.
          </li>
        </ul>
        <h2 className="text-2xl font-medium text-dark mb-4">2. User Content</h2>

        <div className="mb-6 text-lightbrown text-lg">
          <div className="mb-5">
            <span className="text-lg font-medium text-dark mb-5">
              2.1 Ownership :&nbsp;
            </span>
            <span className="mb-4 text-lightbrown text-lg">
              You retain ownership of the content you create and share on the
              App.
            </span>
          </div>

          <div className="mb-5">
            <span className="text-lg font-medium text-dark mb-5">
              2.2 License :&nbsp;
            </span>
            <span className="mb-4 text-lightbrown text-lg">
              By posting content on the App, you grant Mixxer a non-exclusive,
              royalty-free, worldwide license to use, modify, reproduce, and
              distribute your content for the purpose of operating and promoting
              the App.
            </span>
          </div>
        </div>

        <h2 className="text-2xl font-medium text-dark mb-1">3. Privacy</h2>
        <p className="mb-6 text-lightbrown text-lg">
          Our Privacy Policy governs the collection, use, and disclosure of your
          personal information. By using the App, you consent to the terms of
          our Privacy Policy.
        </p>

        <h2 className="text-2xl font-medium text-dark mb-1">
          4. Intellectual Property
        </h2>
        <p className="mb-6 text-lightbrown text-lg">
          The App and its content, including text, graphics, logos, and images,
          are protected by copyright and other intellectual property laws. You
          agree not to reproduce, distribute, or create derivative works based
          on the App without our prior written consent.
        </p>

        <h2 className="text-2xl font-medium text-dark mb-1">
          5. Disclaimer of Warranties
        </h2>
        <p className="mb-6 text-lightbrown text-lg">
          The App is provided on an "as is" and "as available" basis without
          warranties of any kind. We disclaim all warranties, express or
          implied, including but not limited to merchantability, fitness for a
          particular purpose, and non-infringement.
        </p>

        <h2 className="text-2xl font-medium text-dark mb-1">
          6. Limitation of Liability
        </h2>
        <p className="mb-6 text-lightbrown text-lg">
          To the fullest extent permitted by law, we shall not be liable for any
          indirect, incidental, special, consequential, or punitive damages
          arising out of or related to your use of the App.
        </p>

        <h2 className="text-2xl font-medium text-dark mb-1">
          7. Indemnification
        </h2>
        <p className="mb-6 text-lightbrown text-lg">
          You agree to indemnify and hold Mixxer harmless from any claims,
          losses, liabilities, damages, costs, or expenses (including attorney's
          fees) arising out of or related to your use of the App or violation of
          these Terms.
        </p>

        <h2 className="text-2xl font-medium text-dark mb-1">
          8. Modification to Terms
        </h2>
        <p className="mb-6 text-lightbrown text-lg">
          We reserve the right to modify or update these Terms at any time. We
          will notify you of any material changes by posting the updated Terms
          within the App.
        </p>

        <h2 className="text-2xl font-medium text-dark mb-1">
          9. Governing Law
        </h2>
        <p className="mb-6 text-lightbrown text-lg">
          These Terms are governed by and construed in accordance with the laws
          of [Your Jurisdiction], without regard to its conflict of laws
          principles.
        </p>

        <h2 className="text-2xl font-medium text-dark mb-1">10. Contact Us</h2>
        <p className="text-lightbrown text-lg">
          If you have any questions or concerns about these Terms, please
          contact us at admin.mixxerco.com.
        </p>
      </div>
    </div>
  );
};

export default Layout(TermsPolicies);
