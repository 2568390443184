import React, { useState } from "react";
import logo from "../assets/images/white_logo.svg";
import brownLogo from "../assets/images/brown_logo.svg";
import hamburger from "../assets/images/hamburger.svg";
import Drawer from "./Drawer";

export const HeaderSection = ({ handleClick, activeTab, tabs }) => {
  const [isDrawer, setState] = useState(false);

  const handleClose = (id, action) => {
    setState(false);
    if (action == "icon" && id) {
      handleClick(id);
    }
  };
  return (
    <>
      <nav className=" w-full bg-transparent z-10">
        <div className="flex justify-between items-center flex-col py-6">
          <div className="relative w-full">
            <img
              src={logo}
              alt="Logo"
              width={158}
              height={30}
              className="mx-auto"
            />
            <div
              className="bg-light p-3 rounded-[4px] flex items-center justify-center absolute top-[-3px] left-[20px] shadow lg:hidden"
              onClick={() => setState(true)}
            >
              <img
                src={hamburger}
                alt="Logo"
                width={19}
                height={12}
                className="w-[19px] h-[12px]"
              />
            </div>
          </div>
          <div
            className="text-2xl w-[100%] font-medium text-center text-white border-b border-primary dark:text-white dark:border-white lg:flex sm:hidden max-sm:hidden md:hidden"
            style={{ borderColor: 'rgba(126, 108, 84, 0.4)' }}
          >
            <div className="w-[55%] mx-auto">
              <ul className="flex flex-wrap justify-between mt-[30px]">
                {tabs.map((tab) => (
                  <li
                    key={tab.id}
                    className="cursor-pointer px-4"
                    onClick={() => handleClick(tab.id)}
                  >
                    <p
                      className={` py-2 border-b-2 border-transparent text-xl text-white font-normal cursor-pointer rounded-t-lg 
                    hover:text-white hover:border-white 
                    dark:hover:text-white ${
                      activeTab === tab.id && " border-b-2 border-white"
                    }`}
                    >
                      {tab.label}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {isDrawer && (
        <Drawer
          isOpen={isDrawer}
          handleClose={handleClose}
          activeTab={activeTab}
          tabs={tabs}
        />
      )}
    </>
  );
};

export const HeaderWhiteSection = ({ handleClick, activeTab, tabs }) => {
  const [isDrawer, setState] = useState(false);

  const handleClose = (id, action) => {
    setState(false);
    if (action == "icon" && id) {
      handleClick(id);
    }
  };
  return (
    <>
      <nav className=" w-full bg-white z-10">
        <div className="flex justify-between items-center flex-col p-6">
          <div className="relative w-full">
            <img
              src={brownLogo}
              alt="Logo"
              width={158}
              height={30}
              className="mx-auto"
            />
            <div
              className="bg-white p-3 rounded-[4px] flex items-center justify-center absolute top-[-3px] shadow left-0 lg:hidden"
              onClick={() => setState(true)}
            >
              <img
                src={hamburger}
                alt="Logo"
                width={19}
                height={12}
                className="w-[19px] h-[12px]"
              />
            </div>
          </div>
          <div className="text-2xl w-[80%] lg:w-[90%] font-medium text-center text-dark border-b border-lightbrown dark:text-dark dark:border-white lg:flex sm:hidden max-sm:hidden md:hidden">
            <div className="w-[55%] mx-auto">
              <ul className="flex flex-wrap justify-between mt-[30px]">
                {tabs.map((tab) => (
                  <li
                    key={tab.id}
                    className="cursor-pointer"
                    onClick={() => handleClick(tab.id)}
                  >
                    <p
                      className={`px-4 py-2 border-b-2 border-transparent text-xl text-dark font-normal cursor-pointer rounded-t-lg 
                    hover:text-dark hover:border-white 
                    dark:hover:text-dark ${
                      activeTab === tab.id && " border-b-2 border-white"
                    }`}
                    >
                      {tab.label}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {isDrawer && (
        <Drawer
          isOpen={isDrawer}
          handleClose={handleClose}
          activeTab={activeTab}
          tabs={tabs}
        />
      )}
    </>
  );
};
