import React, { useEffect, useState } from "react";
import HeaderSection, { HeaderWhiteSection } from "./components/HeaderSection";
import FooterSection from "./components/FooterSection";
import { useNavigate } from "react-router-dom";

const tabs = [
  { id: "home", label: "Home" },
  { id: "about", label: "About" },
  { id: "services", label: "Features" },
  { id: "faq", label: "FAQ" },
  { id: "contact", label: "Contact" },
];

const usefullLinks = [
  { name: "Home", id: "home" },
  { name: "About", id: "about" },
  { name: "Features", id: "services" },
  { name: "FAQ", id: "faq" },
];

export const Layout = (WrappedComponent) => {
  function HOC(props) {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("home");
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      tabs.forEach((tab) => {
        const section = document.getElementById(tab.id);
        if (
          section?.offsetTop <= scrollPosition + 50 &&
          section?.offsetTop + section?.offsetHeight > scrollPosition
        ) {
          setActiveTab(tab.id);
        }
      });
    };

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("scroll", toggleVisibility);
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("scroll", toggleVisibility);
      };
    }, []);

    const handleClick = (id) => {
      if (window?.location?.pathname != "/") {
        navigate("/");
      }
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
      setActiveTab(id);
    };

    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return (
      <div>
        <div className="wrapper_content">
          {window?.location?.pathname != "/" && (
            <HeaderWhiteSection
              handleClick={handleClick}
              activeTab={activeTab}
              tabs={tabs}
            />
          )}
          <WrappedComponent
            {...props}
            handleClick={handleClick}
            activeTab={activeTab}
            tabs={tabs}
          />
        </div>
        <FooterSection usefullLinks={usefullLinks} handleClick={handleClick} />
        {isVisible && (
          <div className="fixed bottom-4 right-4 z-50">
            <button
              onClick={scrollToTop}
              className=" rounded-full bg-primary w-[40px] h-[40px] text-white shadow-md hover:bg-primary focus:outline-none"
            >
              <p className="text-5xl"> ↑</p>
            </button>
          </div>
        )}
      </div>
    );
  }

  return HOC;
};

export default Layout;
