import React from "react";

import appStore from "../assets/images/appstore.png";
import playStore from "../assets/images/playstore.png";
import { HeaderSection } from "./HeaderSection";

const HeroSection = ({ handleClick, activeTab, tabs }) => {
  return (
    <div className="bg_gradient max-sm:bg-cover sm:bg-cover h-[753px] max-sm:h-[536px]">
      <div className="h-full max-w-[1180px] mx-auto">
        <HeaderSection
          handleClick={handleClick}
          activeTab={activeTab}
          tabs={tabs}
        />
        <section className="flex justify-center items-center min-h-[calc(100%-153px)] w-[90%] mx-auto py-12">
          <div className="text-center">
            <h2 className="text-22xl max-sm:text-7xl font-bold text-white mb-4">
              Ready to amplify your social life?
            </h2>
            <p className="text-2xl max-sm:text-md text-white mb-6 w-[55%] mx-auto text-center md:w-[70%] max-sm:w-[80%]">
              Experience the next level of social networking with Mixxer.
              Download Mixxer now and let the adventure begin!
            </p>
            <div className="mt-6 w-[280px] max-sm:w-[50] flex justify-center mx-auto space-x-4">
              <img
                src={playStore}
                className="w-[134px] h-[45px]"
                alt="social"
              />
              <img src={appStore} className="w-[134px] h-[45px]" alt="social" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HeroSection;
