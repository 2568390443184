import React from "react";
import mobileView from "../../assets/images/explore-phone_mobile.svg";
import blurImage from "../../assets/images/blur.svg";

const features = [
  {
    title: "Featured Mixxers",
    description:
      "Explore top picks specially highlighted for you. Discover Mixxers from boosted users and trending activities, ensuring you join the most exciting and highly-rated gatherings.",
  },
  {
    title: " Friend Mixxers",
    description:
      " Access Mixers created by users you are connected with. Keep up with your friends' activities, join their planned outings, and enjoy socializing with people you know and trust.",
  },
  {
    title: "Recommended Mixxers",
    description:
      "Personalized Mixer suggestions based on your interests. Discover gatherings that match your preferences and make meaningful connections effortlessly.",
  },
];

const EssentialsSection = () => {
  return (
    <>
      <div className="text-center">
        <h3 className="text-12xl max-sm:text-5xl font-lightBold">
          Mixxer Essentials: Your Guide to the Home Page
        </h3>
      </div>
      <div className="bg_essentials_xx_image xl:block lg:block md:hidden max-sm:hidden sm:hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-sm:gap-1 bg_essentials h-[650px] relative">
          <img
            src={blurImage}
            className="mx-auto absolute w-full h-[200px] bottom-[-5%]"
          />
          <div className=" rounded-xl xl:w-[385px] lg:w-[330px] md:w-[300px] max-sm:w-[90%] max-sm:mx-auto bg-darker min-h-[170px] max-sm:h-fit px-[20px] py-[18px] max-sm:py-[18px] absolute top-[5%] left-[0%] ">
            <h4 className="text-2xl font-lightBold text-white mb-2">
              Featured Mixxers
            </h4>
            <p className="text-md font-normal text-white leading-relaxed">
              Explore top picks specially highlighted for you. Discover Mixxers
              from boosted users and trending activities, ensuring you join the
              most exciting and highly-rated gatherings.
            </p>
          </div>
          <div className=" rounded-xl xl:w-[385px] lg:w-[330px] md:w-[300px] max-sm:w-[90%] max-sm:mx-auto bg-darker min-h-[170px] max-sm:h-fit px-[20px] py-[18px] max-sm:py-[18px] absolute top-[49%] left-[0%]">
            <h4 className="text-2xl font-lightBold text-white mb-2">
              Recommended Mixxers
            </h4>
            <p className="text-md font-normal text-white leading-relaxed">
              Personalized Mixer suggestions based on your interests. Discover
              gatherings that match your preferences and make meaningful
              connections effortlessly.
            </p>
          </div>
          <div className=" rounded-xl xl:w-[385px] lg:w-[330px] md:w-[300px] max-sm:w-[90%] max-sm:mx-auto bg-darker min-h-[170px] max-sm:h-fit px-[20px] py-[18px] max-sm:py-[18px] absolute top-[25%] left-[67.3%]">
            <h4 className="text-2xl font-lightBold text-white mb-2">
              Friend Mixxers
            </h4>
            <p className="text-md font-normal text-white leading-relaxed">
              Access Mixers created by users you are connected with. Keep up
              with your friends' activities, join their planned outings, and
              enjoy socializing with people you know and trust.
            </p>
          </div>
        </div>
      </div>

      <div className="lg:hidden flex flex-col gap-[40px]">
        <div className=" mt-5 relative">
          <div className="bg_xx_image_mobile">
            <img
              src={mobileView}
              className="mx-auto"
              width={"201px"}
              height={"398px"}
            />
          </div>

          <div className="grid gap-[12px] relative top-[-32px]">
            {features?.map((feature) => {
              return (
                <div className=" rounded-xl max-sm:mx-auto bg-darker lg:h-[170px] px-[20px] py-[18px]">
                  <h4 className="text-lg font-lightBold text-white mb-2">
                    {feature?.title}
                  </h4>
                  <p className="text-md font-normal text-white leading-relaxed">
                    {feature?.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default EssentialsSection;
