import React from "react";
import brownLogo from "../assets/images/brown_logo.svg";
import facebook from "../assets/images/facebook.svg";
import linkedin from "../assets/images/linkedIn.svg";
import youtube from "../assets/images/youtube.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import tiktok from "../assets/images/tik-tok.svg";
import xImage from "../assets/images/x.png";
import bgCircle from "../assets/images/bg-circle.svg";
import { useNavigate } from "react-router-dom";

const termsPolicies = [
  { name: "Terms of Services", route: "/terms" },
  { name: "EULA for Mixxer", route: "/eula" },
  { name: "Privacy Policies", route: "/privacy" },
  { name: "Disclaimer for Mixxer", route: "/disclaimer" },
];

const socialIconsWithLinks = [
  {
    icon: facebook,
    link: "https://www.facebook.com/profile.php?id=61563997598865&mibexid=LQQJ4d",
  },
  // { icon: twitter, link: "https://x.com/?lang=en", isBG: true },
  // { icon: youtube, link: "https://www.youtube.com/", isBG: true },
  { icon: linkedin, link: "https://www.linkedin.com/company/mixxerco/" },
  {
    icon: instagram,
    link: "https://www.instagram.com/mixxerco?igsh=b3dxN2x5NWxjOGs4",
  },
  {
    icon: tiktok,
    link: "https://www.tiktok.com/@mixxerco?_t=8pAO977p77a&_r=1",
  },
  {
    icon: xImage,
    link: "https://x.com/mixxerco?s=21&t=e6NLNHahWV45qa63wsDODw",
  },
];
const FooterSection = ({ usefullLinks, handleClick }) => {
  const navigate = useNavigate();

  const handleRoute = (tab) => {
    navigate(tab?.route);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer className="bg-dark pt-20 lg:px-24 md:px-16 max-sm:px-4">
        <div className="container mx-auto flex justify-between items-start text-white lg:flex-row md:flex-col max-sm:flex-col sm:flex-col max-w-[1180px]">
          <div className="lg:w-[40%] md:mx-auto max-sm:mx-auto sm:mx-auto">
            <div className="lg:text-start md:text-center max-sm:text-center sm:text-center">
              <img
                src={brownLogo}
                className="w-[178px] h-[36px] lg:mx-0 md:mx-auto max-sm:mx-auto sm:mx-auto"
                alt="img"
              />
              <div className="lg:w-[60%] my-10">
                <p className="text-lg font-normal text-white leading-relaxed">
                  Don't miss out on the latest updates, announcements, and
                  exclusive offers. Connect with us on social media and
                  subscribe.
                </p>
              </div>
              <a
                href="mailto:contactus@mixxerco.com"
                className="text-white text-xl font-normal hover:text-gray-200 transition duration-300 ease-in-out"
              >
                contactus@mixxerco.com
              </a>
            </div>
          </div>
          <div className="lg:w-[40%] md:w-full max-sm:w-full sm:w-full flex lg:my-0 md:my-20 max-sm:my-20 sm:my-20 ">
            <div className="w-[50%]">
              <h4 className="text-2xl font-medium text-white mb-4">
                Useful Links
              </h4>
              <ul>
                {usefullLinks?.map((tab) => {
                  return (
                    <li
                      className="text-white cursor-pointer text-lg leading-[60px] font-normal hover:text-gray-200 transition duration-300 ease-in-out"
                      onClick={() => handleClick(tab?.id)}
                    >
                      {tab?.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="w-[50%]">
              <h4 className="text-2xl font-medium text-white mb-4">
                Terms & Policies
              </h4>
              <ul>
                {termsPolicies?.map((tab) => {
                  return (
                    <li
                      className="cursor-pointer text-white text-lg leading-[60px] font-normal hover:text-gray-200 transition duration-300 ease-in-out"
                      onClick={() => handleRoute(tab)}
                    >
                      {tab?.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="lg:w-[20%]">
            <h4 className="text-2xl font-medium text-white mb-10">
              Get in Touch
            </h4>
            <div className="flex flex-wrap gap-[20px]">
              {socialIconsWithLinks?.map(({ icon, link }) => (
                <div
                  className="w-[33.06px] h-[33.06px] rounded-lg flex justify-center items-center"
                  style={{ backgroundImage: `url(${bgCircle})` }}
                >
                  <a
                    target="_blank"
                    href={link}
                    className={`flex justify-center items-center
                    } transition duration-300 ease-in-out`}
                  >
                    <img
                      src={icon}
                      className={`w-[13.56px] h-[13.56px] }`}
                      alt="img"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <hr className="max-w-[1180px] h-[1px] bg-white opacity-20 mx-auto" />
          <p className="p-3 text-md text-white font-normal">
            &copy; Copyright 2024 All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default FooterSection;
